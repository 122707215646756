import { useEffect, useState } from "react";
// for work_images
import work1 from "../assets/images/work_images/1.jpg";
import work2 from "../assets/images/work_images/2.jpg";
import work3 from "../assets/images/work_images/3.jpg";
import work4 from "../assets/images/work_images/4.jpg";
import work5 from "../assets/images/work_images/5.jpg";
import work6 from "../assets/images/work_images/6.jpg";
import work7 from "../assets/images/work_images/7.jpg";
import work8 from "../assets/images/work_images/8.jpg";
import work9 from "../assets/images/work_images/9.jpg";
// works small images
import workSmall1 from "../assets/images/work_images/small/1.jpg";
import workSmall2 from "../assets/images/work_images/small/2.jpg";
import workSmall3 from "../assets/images/work_images/small/3.jpg";
import workSmall4 from "../assets/images/work_images/small/4.jpg";
import workSmall5 from "../assets/images/work_images/small/5.jpg";
import workSmall6 from "../assets/images/work_images/small/6.jpg";
import workSmall7 from "../assets/images/work_images/small/7.jpg";
import workSmall8 from "../assets/images/work_images/small/8.jpg";
import workSmall9 from "../assets/images/work_images/small/9.jpg";

// blog image small
import kcidbBlog from "../assets/images/blog_images/kcidb.png";
import hashnodeOpenSource from "../assets/images/blog_images/hashnode_opensource.jpeg";
import hashnodeEmail from "../assets/images/blog_images/hashnode_emails.jpeg";
import devWrapUp from "../assets/images/blog_images/dev-wrap-up.jpeg";

import img1 from "../assets/images/slider/afex.png";
import img2 from "../assets/images/slider/kernelci.png";
import img3 from "../assets/images/slider/outreachy.png";
import img4 from "../assets/images/slider/fulfil.svg";
import img5 from "../assets/images/slider/nossadata.png";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon2 from "../assets/images/icons/icon-3.svg";
import icon3 from "../assets/images/icons/icon-4.svg";
import icon4 from "../assets/images/icons/icon-5.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import { CgNotes } from "react-icons/cg";
import { FaBlogger, FaRegUser, FaLaptopCode } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

// tech stack images 
import Python from "../assets/images/techstack/python.jpeg";
import CSSImg from "../assets/images/techstack/css.png";
import Django from "../assets/images/techstack/django.svg";
import Flask from "../assets/images/techstack/flask.png";
import HTML from "../assets/images/techstack/html.png";
import Javascript from "../assets/images/techstack/js.png";
import ReactImg from "../assets/images/techstack/react.png";
import Tryton from "../assets/images/techstack/tryton.png";
import TS from "../assets/images/techstack/typescript.png";
import FastApi from "../assets/images/techstack/fastapi.png";
import Bootsratp from "../assets/images/techstack/bootstrap.png";
import Tailwind from "../assets/images/techstack/tailwind.svg";
import AWS from "../assets/images/techstack/aws.svg";
import Docker from "../assets/images/techstack/docker.png";
import Next from "../assets/images/techstack/nextjs.png";
import NodeJS from "../assets/images/techstack/nodejs.png";
import GCP from "../assets/images/techstack/gcp.png";
import Git from "../assets/images/techstack/git.png";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // fillter portfilo data
  const handleData = (text) => {
    if (text === "All") {
      setData(workItems);
    } else {
      const findData = workItems.filter((item) => item.tag === text);
      setData(findData);
    }
  };

  // find items for portfilo  modal open
  const handleModelData = (id) => {
    const find = workItems.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // find items for Blogs  modal open
  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Elements for protfilo section
  const workItems = [
    {
      id: "1",
      tag: "UI/UX",
      title: "Chul urina",
      img: work1,
      imgSmall: workSmall1,
      bg: "#FFF0F0",
      client: "Envato",
      langages: "Photoshop, Figma",
      link: "https://www.envato.com",
      linkText: "www.envato.com",
      description:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Mollitia placeat magnam possimus iusto blanditiis pariatur labore explicabo quo repellat hic dolorum numquam asperiores, voluptatum fugiat reiciendis aspernatur, non, odio aperiam voluptas ex tempora vitae. Dolor, consequatur quidem! Quas magni distinctio dolorum dolore natus, vel numquam accusamus. Nostrum eligendi recusandae qui tempore deserunt!",
    },
    {
      id: "2",
      tag: "Web Design",
      title: "Aura Dione",
      img: work2,
      imgSmall: workSmall2,
      bg: "#FFF3FC",
      client: "Themeforest",
      langages: "HTML, CSS, Javascript",
      link: "https://www.themeforest.net",
      linkText: "themeforest.net",
      description:
        "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    },
    {
      id: "3",
      tag: "Logo",
      title: "Chul urina",
      img: work3,
      imgSmall: workSmall3,
      bg: "#FFF0F0",
      client: "Freepik",
      langages: "Illustrator",
      link: "https://www.freepik.com/free-photos-vectors/market-logo",
      linkText: "www.freepik.com",
      description:
        "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    },
    {
      id: "4",
      tag: "Video",
      title: "Chul urina",
      img: work4,
      imgSmall: workSmall4,
      bg: "#E9FAFF",
      client: "Envato",
      langages: "After Effect",
      link: "https://www.envato.com",
      linkText: "www.envato.com",
      description:
        "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    },
    {
      id: "5",
      tag: "UI/UX",
      title: "Chul urina",
      img: work5,
      imgSmall: workSmall5,
      bg: "#FFFAE9",
      client: "Envato",
      langages: "Photoshop",
      link: "https://www.envato.com",
      linkText: "www.envato.com",
      description:
        "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    },
    {
      id: "6",
      tag: "Video",
      title: "Chul urina",
      img: work6,
      imgSmall: workSmall6,
      bg: "#F4F4FF",
      client: "Envato",
      langages: "Vimeo",
      link: "https://www.envato.com",
      linkText: "www.envato.com",
      description:
        "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    },
    {
      id: "7",
      tag: "UI/UX",
      title: "Chul urina",
      img: work7,
      imgSmall: workSmall7,
      bg: "#FFF0F8",
      client: "Envato",
      langages: "Photoshop",
      link: "https://www.envato.com",
      linkText: "www.envato.com",
      description:
        "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    },
    {
      id: "8",
      tag: "Web Design",
      title: "Chul urina",
      img: work8,
      imgSmall: workSmall8,
      bg: "#FFF0F8",
      client: "Envato",
      langages: "HTML, CSS, Javascript",
      link: "https://www.envato.com",
      linkText: "www.envato.com",
      description:
        "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    },
    {
      id: "9",
      tag: "Logo",
      title: "Chul urina",
      img: work9,
      imgSmall: workSmall9,
      bg: "#FCF4FF",
      client: "Feepik",
      langages: "Figma",
      link: "https://www.freepik.com/free-photos-vectors/market-logo",
      linkText: "www.freepik.com",
      description:
        "  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate non suscipit voluptatibus minima ullam maiores sequi nihil placeat error, vero eaque doloremque reiciendis amet pariatur consequuntur. Nostrum, dolore, amet eligendi ipsam enim quisquam, corrupti asperiores nihil excepturi aspernatur placeat iure.",
    },
  ];
  const [data, setData] = useState(workItems);

  // Elements for Blogs section
  const blogsData = [
    {
      id: "1",
      img: "",
      imgSmall: kcidbBlog,
      date: "Jan 2022",
      category: "Open Source",
      title: "A look into my project: KernelCI",
      bg: "#FCF4FF",
      description:
        "",
      link: "https://dev.to/maryam/a-look-into-my-project-kernelci-4gmk",
    },
    {
      id: "2",
      img: "",
      imgSmall: hashnodeOpenSource,
      date: "Sep 2022",
      category: "Open Source",
      title: "My Journey to the Open Source Community",
      bg: "#FCF4FF",
      description:
        "",
      link: "https://maryam.hashnode.dev/my-journey-to-the-open-source-community",
    },
    {
      id: "3",
      img: "",
      imgSmall: hashnodeEmail,
      date: "Sep 2022",
      category: "Python",
      title: "Send emails from Gmail without turning on 'Allow less secure apps.'",
      bg: "#FCF4FF",
      description:
        "",
      link: "https://maryam.hashnode.dev/send-emails-from-gmail-without-turning-on-allow-less-secure-apps",
    },
    {
      id: "4",
      img: "",
      imgSmall: devWrapUp,
      date: "March 2022",
      category: "Open Source",
      title: "All about my Outreachy journey -- Wrap Up",
      bg: "#FCF4FF",
      description:
        "",
      link: "https://dev.to/maryam/all-about-my-outreachy-journey-wrap-up-5djo",
    },
  ];

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "03",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
    // {
    //   id: "04",
    //   name: "Works",
    //   link: "/works",
    //   icon: <FiCodesandbox />,
    // },
    {
      id: "05",
      name: "Blogs",
      link: "/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "06",
      name: "Contact",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];


  // Slider image for Clients
  const sliderImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img1,
    img2,
    img3,
    img4,
    img5,
  ];

  // experience items for about page
  const experienceArray = [
    {
      id: "1",
      icon: icon,
      title: "Ui/Ux Design",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      icon: icon1,
      title: "App Development",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon2,
      title: "Photography",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: "4",
      icon: icon3,
      title: "Photography",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    {
      id: "5",
      icon: icon4,
      title: "Managment",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    {
      id: "6",
      icon: icon5,
      title: "Web Development",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: "01",
      date: "August 2022 – Present",
      title: "Full Stack Software Engineer",
      place: "Nossa Data",
      bg: "#EEF5FA",

      id1: "02",
      date1: "Dec 2021 - April 2022",
      title1: "Open Source Contributor (via Outreachy)",
      place1: "KernelCI",
      bg1: "#F2F4FF",

      id2: "03",
      date2: "Apr 2019 - Dec 2021",
      title2: "Software Engineer",
      place2: "AFEX",
      bg2: "#EEF5FA",
    },
    {
      type: "Projects / Open Source",
      icon: FaLaptopCode,
      id: "01",
      date: "2022 - Present",
      title: "Engineering Lead",
      place: "GingerEdu",
      bg: "#FCF4FF",

      id1: "02",
      date1: "2022",
      title1: "Software Engineer",
      place1: "KCIDB",
      bg1: "#FCF9F2",
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "Software Engineering",
      number: "100",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Cloud Data Engineer",
      number: "100",
    },
    {
      id: "03",
      color: "#CA56F2",
      name: "Agile Scrum",
      number: "100",
    },
  ];

  // Personal information for contact pages
  const contactArray = [
    {
      id: "01",
      icon: iconEmail,
      title: "Email ",
      item1: "maryamyuf@gmail.com",
      bg: "#EEFBFF",
    },
    {
      id: "02",
      icon: iconMap,
      title: "Address ",
      item1: "London, United Kingdom",
      bg: "#F2F4FF",
    },
  ];

  // Slider image for Clients
  const techStackArray = [
    {
      name: "Python",
      url: "https://www.python.org/",
      img: Python
    },
    {
      name: "Django",
      url: "https://www.djangoproject.com/",
      img: Django
    },
    {
      name: "Flask",
      url: "https://flask.palletsprojects.com/en/2.2.x/",
      img: Flask
    },
    {
      name: "Javascript",
      url: "https://www.javascript.com/",
      img: Javascript
    },
    {
      name: "React",
      url: "https://reactjs.org/",
      img: ReactImg
    },
    {
      name: "Tryton",
      url: "https://www.tryton.org/",
      img: Tryton
    },
    {
      name: "TypeScript",
      url: "https://www.typescriptlang.org/",
      img: TS
    },
    {
      name: "NodeJS",
      url: "https://nodejs.org/en/",
      img: NodeJS
    },
    {
      name: "NEXT.js",
      url: "https://nextjs.org/",
      img: Next
    },
    {
      name: "HTML",
      url: "https://developer.mozilla.org/en-US/docs/Web/HTML",
      img: HTML
    },
    {
      name: "CSS",
      url: "https://developer.mozilla.org/en-US/docs/Web/CSS",
      img: CSSImg
    },
    {
      name: "Git",
      url: "https://git-scm.com/",
      img: Git
    },
    {
      name: "FastApi",
      url: "https://fastapi.tiangolo.com/",
      img: FastApi
    },
    {
      name: "Bootsratp",
      url: "https://getbootstrap.com/",
      img: Bootsratp
    },
    {
      name: "Tailwind",
      url: "https://tailwindcss.com/",
      img: Tailwind
    },
    {
      name: "AWS",
      url: "https://aws.amazon.com/",
      img: AWS
    },
    {
      name: "Docker",
      url: "https://www.docker.com/",
      img: Docker
    },
    {
      name: "GCP",
      url: "https://cloud.google.com/",
      img: GCP
    },
  ]

  return {
    handleTheme,
    check,
    local,
    handleData,
    data,
    singleData,
    handleModelData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
    menuItem,
    NavLink,
    experienceArray,
    sliderImg,
    resumeArray,
    lineArray,
    contactArray,
    techStackArray
  };
};

export default AllData;
