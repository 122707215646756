import React from "react";
import { v4 as uuidv4 } from 'uuid';

import UseData from "../../Hooks/UseData";

const TechStack = ({ item, local }) => {
  const { techStackArray } = UseData();
  return (
    <div
      style={{ background: `${local === "dark" ? "transparent" : item?.bg}` }}
      className="grid grid-cols-2 gap-3 md:grid-cols-6"
    >
      {techStackArray.map((skill) => {
          return(
            <a key={uuidv4()} href={skill.url} target="_blank">
              <div 
              style={{ background: `${local === "dark" ? "transparent" : item?.bg}` }}
              className="skill dark:border-[#212425]">
                <img src={skill.img} alt="Skill logo image" />
              </div>
              <p className="font-light text-2xl	text-center	mt-4 dark:text-white text-2xl font-semibold">{skill.name}</p>
            </a>
          )
        })}
    </div>
  );
};
export default TechStack;
