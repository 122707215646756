import React from "react";
// import {
//   FaEnvelopeOpenText,
//   FaMapMarkerAlt,
//   FaMobileAlt,
//   FaRegCalendarAlt,
// } from "react-icons/fa";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import aboutImage from "../../assets/images/about/aboutme.JPG";
import "../../Share/Style.css";
import Footer from "../../Share/Footer";
// import UseData from "../../Hooks/UseData";
import SliderCommon from "../../Share/SliderCommon";
import PageTitle from "../../Share/PageTitle";
import TechStack from "./techstack";

const About = () => {
  return (
    <>
      <PageTitle title="About"></PageTitle>
      {/* End pagetitle */}

      <section>
        <div className="container lg:rounded-2xl bg-white dark:bg-[#111111]  px-4 sm:px-5 md:px-10 lg:px-20">
          <div data-aos="fade">
            <div className="py-12">
              {/* Page Title */}
              <h2 className="after-effect after:left-52 mt-12 lg:mt-0 ">
                About Me
              </h2>
              <div className="grid grid-cols-12 md:gap-10 pt-4 md:pt-[40px] items-center">
                <div className="col-span-12 md:col-span-4">
                  {/* personal images for about page  */}
                  <img
                    className="w-full md:w-[330px] md:h-[400px] object-cover overflow-hidden rounded-[35px] mb-3 md:mb-0"
                    src={aboutImage}
                    alt="About me"
                  />
                </div>
                <div className="col-span-12 md:col-span-8 space-y-2.5">
                  {/* About me information */}
                  <div className=" md:mr-12 xl:mr-16">
                    <h3 className="text-4xl font-medium dark:text-white mb-2.5 ">
                      Who am i?
                    </h3>
                    <p className="text-gray-lite  dark:text-color-910 leading-7">
                    Hello, my name is Maryam Yusuf. 
                    I have a computer science background and four years of experience in the field.
                    I enjoy creating useful tools and applications that live on the internet. 
                    Throughout my career, I have worked on a variety of projects in diverse domains such as AgriTech,
                    Ecommerce, NGO, ESG, EdTech, and Open Source.

                    </p>
                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                      I am  am always looking for opportunities to use my skills and knowledge 
                      to build innovative solutions and help businesses succeed.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="  pb-12 ">
              <h3 className="text-[35px] dark:text-white font-medium pb-5">
                Some Stack I have worked with
              </h3>
              {/* <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 "> */}
                {/* Experience information  */}

                {/* {experienceArray.map((item, i) => (
                  <AboutCard key={i} item={item} local={local} />
                ))} */}
                <TechStack/>
              {/* </div> */}
            </div>

            <div>
              {/* Slick Slider call here  */}
              <SliderCommon />
            </div>

            {/* Common Footer call here */}
            <Footer condition={false} bg={"#FFFF"} />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
